import React, { useState, useCallback, useEffect } from "react"
import { FilterGrid, SearchInput, Work } from "./style"
import { SelectInput } from "../SelectInput"
import { AllCategories } from "../../queries/GetCategories"
import { FlexRow, FlexCol, FlexGrid } from "../FlexGrid"
import { TeaserLarge } from "../TeaserLarge"
import { getTags } from "../../utils/helpers"

import { ThreeColumnTeaser } from "../ThreeColumnGrid/style"

import algoliasearch from "algoliasearch"
import useDidMountEffect from "../../hooks/didMount"
import { LoadMore } from "../LoadMore"

const client = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_API_KEY
)
const index = client.initIndex(process.env.GATSBY_ALGOLIA_INDEX_NAME)

const Filters = ({ defaultData }) => {
  const allOption = { value: "", label: "All" }

  const [loadMore, setLoadMore] = useState(false)
  const [posts, setPosts] = useState(defaultData)

  const [state, setState] = useState({
    selectedCategory: allOption,
    selectedIndustry: allOption,
    searchText: "",
    currentPage: 1,
  })

  const allCatFacet = getAllFacetArray(AllCategories().categories)
  const allIndustryFacet = getAllFacetArray(AllCategories().industries)

  // const defaultState =
  //   (state.currentPage === 1) & (state.selectedCategory.value === "") &&
  //   state.selectedIndustry.value === "" &&
  //   state.searchText.length === 0

  const findResults = useCallback(() => {
    let catFacet = allCatFacet
    let industryFacet = allIndustryFacet

    if (state.selectedCategory.value.length) {
      catFacet = [`categories:${state.selectedCategory.value}`]
    }

    if (state.selectedIndustry.value.length) {
      industryFacet = [`categories:${state.selectedIndustry.value}`]
    }

    // const aFacets = [...catFacet, ...industryFacet]

    const options = {
      page: state.currentPage - 1,
      facetFilters: [[`type: casestudy`], catFacet, industryFacet], //add to this for categories
      hitsPerPage: 100,
    }

    index.search(state.searchText, options).then(({ hits, nbPages }) => {
      const mergedHits = [...posts, ...hits]

      setPosts(state.currentPage === 1 ? hits : mergedHits)

      if (state.currentPage >= nbPages) {
        setLoadMore(false)
      } else {
        setLoadMore(true)
      }
    })
  }, [state])

  const handleCategorySelect = (e, stateKey) => {
    setState({
      ...state,
      currentPage: 1,
      [stateKey]: e,
    })
  }

  useEffect(() => {}, [])

  useDidMountEffect(() => {
    findResults()
  }, [findResults])

  return (
    <Work>
      <FilterGrid
        gap={[20, 30, 60]}
        rowGap={[0, 0, 0]}
        style={{ zIndex: 23, position: "relative" }}
      >
        <FlexRow>
          <FlexCol xs={12} sm={4}>
            <SearchInput
              placeholder="Enter keywords…"
              // autoFocus={false}
              onChange={e =>
                setState({
                  ...state,
                  currentPage: 1,
                  searchText: e.target.value,
                })
              }
              value={state.searchText}
            />
          </FlexCol>
          <FlexCol xs={12} sm={4}>
            <SelectInput
              labelPrefix="Category:"
              options={[allOption, ...AllCategories().categories]}
              value={state.selectedCategory}
              onChange={e => handleCategorySelect(e, "selectedCategory")}
            />
          </FlexCol>
          <FlexCol xs={12} sm={4}>
            <SelectInput
              labelPrefix="Industry:"
              options={[allOption, ...AllCategories().industries]}
              value={state.selectedIndustry}
              onChange={e => handleCategorySelect(e, "selectedIndustry")}
            />
          </FlexCol>
        </FlexRow>
      </FilterGrid>

      <FlexGrid gap={[20, 30, 60]} rowGap={[20, 30, 60]}>
        <FlexRow>
          {posts.map((page, index) => {
            if (page.featuredImage) {
              page.featuredImage.aspectRatio = 1
            }

            if (index > 7) {
              return (
                <FlexCol key={page.link} xs={12} sm={4} gap={[20, 20, 20]}>
                  <ThreeColumnTeaser
                    title={page.title}
                    img={{
                      ...page.featuredImage,
                      sizes: "(min-width: 1024px) 370px, 100vw",
                    }}
                    tag={getTags(page.categories.nodes || page.categories)}
                    titleLink={page.link}
                  />
                </FlexCol>
              )
            }

            return (
              <FlexCol key={page.link} xs={12} sm={6} gap={[20, 20, 20]}>
                <TeaserLarge
                  title={page.title}
                  img={page.featuredImage}
                  tag={getTags(page.categories.nodes || page.categories)}
                  link={page.link}
                  theme="index"
                />
              </FlexCol>
            )
          })}
        </FlexRow>
      </FlexGrid>

      {loadMore && (
        <LoadMore
          handleLoadMore={() =>
            setState({
              ...state,
              currentPage: state.currentPage + 1,
            })
          }
        />
      )}
    </Work>
  )
}

export default Filters

const getAllFacetArray = array => {
  let facetArray = array.map(obj => {
    return `categories:${obj.value}`
  })

  return facetArray
}

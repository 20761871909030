import { useStaticQuery, graphql } from "gatsby"

export const AllCategories = () => {
  const { categories, industries } = useStaticQuery(
    graphql`
      query  {
        categories:wpgraphql {
          categories(first: 50, where: {parent: 15}) {
            nodes {
              label: name
              value: name
            }
          }
        
        }
        industries:wpgraphql {
          categories(first: 50, where: {parent: 1}) {
            nodes {
              label: name
              value: name
            }
          }
        
        }
      }
    `
  )




  return {
    categories: categories.categories.nodes,
    industries: industries.categories.nodes,
  }
}

import styled from "styled-components"
import { device } from "breakpoints"
import { FlexGrid } from "../FlexGrid"
import { Search } from "../Search"
import { Input, Button } from "../Search/style"

export const Work = styled.div`
  padding: 0 0 58px;

  @media ${device.desktop} {
    padding: 0 0 96px;
  }
`

export const FilterGrid = styled(FlexGrid)`
  position: relative;
  z-index: 1;
  margin-bottom: 40px;

  @media ${device.desktop} {
    margin-bottom: 80px;
  }
`
export const SearchInput = styled(Search)`
  ${Input} {
    padding-left: 34px;

    @media ${device.desktop} {
      padding-left: 34px;
    }
  }

  ${Button} {
    left: 0;
    right: auto;
  }
`

import React from "react"
import Layout from "components/layout"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "components/seo"
import { Filters } from "../components/Filters"

const WorkIndex = () => {
  const data = useStaticQuery(graphql`
    {
      wpgraphql {
        casestudies(
          first: 100
          where: { orderby: { field: MENU_ORDER, order: ASC } }
        ) {
          nodes {
            title
            link
            categories(where: { parent: 15 }) {
              nodes {
                name
              }
            }
            featuredImage {
              sourceUrl
              srcSet
              mediaDetails {
                height
                width
              }
            }
          }
        }
      }
    }
  `)

  const caseStudies = data.wpgraphql.casestudies.nodes

  return (
    <Layout
      title="Work"
      excerpt="We untangle complicated problems, then define and build smart brands to resolve them. Strong brands address real needs in a way that is particular to them. That idea becomes your fingerprint in the market. Ultimately, a brand’s goodwill and its strength is the accumulation of all the experiences associated with it."
    >
      <SEO title="Work" />

      <Filters defaultData={caseStudies}></Filters>
    </Layout>
  )
}

export default WorkIndex

const getTags = (categories) => {
  let sTags = ""

  if(!categories) return "sup dude";

  categories.forEach((tag, tIndex) => {

    if (tIndex === categories.length - 1) {
      sTags += tag.name || tag
    } else {
      sTags += `${tag.name || tag}, `
    }
  })

  return sTags;
}

export default getTags




            
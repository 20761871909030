import styled from "styled-components"

export const SelectContainer = styled.div`
  width: 100%;

  .basic__value-container {
    padding: 0;
  }

  .css-1hwfws3 {
    padding: 0;
  }

  .basic__control {
    border: none !important;
    border-bottom: 3px solid ${props=> props.theme.primaryColor} !important;
    border-radius: 0;
    box-shadow: none !important;
    outline: none !important;
    height: 50px;
    cursor: pointer;
    padding: 0;
    :hover {
      border-color: 0;
      ${'' /* background-color: #84004b; */}
    }

    @media (min-width:1024px) {
      height: 60px;
    }
  }
  .basic__indicator-separator {
    display: none;
  }
  .basic__menu {
    top: calc(100% - 3px);
    margin: 0;
  }
  .basic__menu-list {
    padding-top: 0;
    background: ${props=>props.theme.primaryColor};
    padding-bottom: 0;
  }

  .basic__option {
    color: #fff;
    font-weight: 500;
    line-height: 16px;
    font-size: 14px;
    letter-spacing: 0;
    padding: 15px;

    @media(min-width: 1024px) {
      padding: 16px 20px;
      font-size: 16px;
    }
  }
  .basic__option--is-focused {
    background-color: #12009D;
    transition: 90ms;

    cursor: pointer;
  }

  .basic__option--is-selected {
    background-color: #12009D;
    color: #fff;
  }
`

export const ArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 16px;
    height: 18px;
    transform: rotateX(${props => (props.open ? "180deg" : "0")});
    transition: ${props => (props.open ? "300ms" : "300ms")};

  }
`

export const SingleValueContainer = styled.div`
  font-size: 14px;

  @media(min-width: 1024px) {
    font-size: 16px;
  }
  > span {
    font-weight: 300;
    color: #5A5A5A;
  }
`

export const Placeholder = styled(SingleValueContainer)`
  background: red;
`

export const LabelPrefix = styled.span`
  font-weight: 500;
  margin-right: 3px;
  color: ${props => props.theme.primaryColor};

  @media(min-width: 1024px) {
    margin-right: 4px;
  }
`
